class PassAuth {

    cookieName = ""
    expireDays = 7

    constructor(cookieName,expireDays =7) {
        this.cookieName = cookieName;
        this.expireDays = expireDays;
    }

    async connect() {
        const loginButton = document.getElementById("login-submit-button");
        const message = document.getElementById("login-message");
        if (loginButton) {
            loginButton.disabled = true;
        }
        if (message) {
            message.innerText = "";
        }
        const password = document.getElementById("password").value;
        const response = await fetch("/api/v1/connect", {
            method: 'POST',
            headers: {
                'X-Pass': password,
                'Auth-Cookie-Name': this.cookieName,
            },
        });
        response.headers.forEach((value, name) => {
            console.log(`${name}: ${value}`);
        });
        const resp = await response.json()
        if (response.ok) {
            window.location = "/"
        } else {
            if (message) {
                message.innerText = JSON.stringify(resp.message);
            }
        }
        if (loginButton) {
            loginButton.disabled = false;
        }
    }

    async disconnect() {
        console.log("Disconnecting");
        const response = await fetch("/api/v1/connect", {
            method: 'DELETE',
            headers: {},
        }).catch(e => {
            console.error(e);
        });
        if (response.ok) {
            window.location = "/"
        }else{
            console.warn("Failed to disconnect")
        }
    }
}
